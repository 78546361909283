.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999; /* Ensure the button is above other content */
    background-color: azure;
    color: rgb(12, 0, 0);
    padding: 10px 20px;
    border-radius: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .whatsapp-button:hover {
    background-color: #70bcb2;
  }
  
  .whatsapp-icon {
    width: 30px;
    height: auto;
    margin-right: 10px;
  }